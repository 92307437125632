import portfolioImg00 from "../data/ux/portfolio-ux/ux-portfolio-landing-page.jpg";
import portfolioImg01 from "../data/ux/portfolio-ux/ux-portfolio-projects.jpg";
import portfolioImg02 from "../data/ux/portfolio-ux/ux-portfolio-uxdesigns.jpg";
import portfolioImg03 from "../data/ux/portfolio-ux/ux-portfolio-aboutme.jpg";

// // import cyfImg00 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-landing-page.jpg";
// import cyfImg01 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-login.jpg";
// import cyfImg02 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-user-signup.jpg";
// import cyfImg03 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-search.jpg";
// import cyfImg04 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-search-term.jpg";
// // import cyfImg05 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-add-term.jpg";
// import cyfImg06 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-update-term.jpg";
// // import cyfImg07 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-flag-term.jpg";
// import cyfImg08 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-user-profile-edit.jpg";
// import cyfImg09 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-mail.jpg";
// import cyfImg10 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-mail-convo.jpg";
// import cyfImg11 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-mentor-profile-edit.jpg";
// import cyfImg12 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-mentor-user-profile-review.jpg";
// import cyfImg13 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-mentor-user-term-edit-review.jpg";
// import cyfImg14 from "../data/ux/cyf-glossary-ux/desktop/cyf-glossary-mentor-user-flag-review.jpg";

import cyfMobileImg00 from "../data/ux/cyf-glossary-ux/mobile/cyf-glossary-landing-page.jpg";
import cyfMobileImg01 from "../data/ux/cyf-glossary-ux/mobile/cyf-glossary-login.jpg";
import cyfMobileImg02 from "../data/ux/cyf-glossary-ux/mobile/cyf-glossary-signup.jpg";
import cyfMobileImg03 from "../data/ux/cyf-glossary-ux/mobile/cyf-glossary-search.jpg";
import cyfMobileImg04 from "../data/ux/cyf-glossary-ux/mobile/cyf-glossary-search-term.jpg";
import cyfMobileImg05 from "../data/ux/cyf-glossary-ux/mobile/cyf-glossary-term-add.jpg";
import cyfMobileImg06 from "../data/ux/cyf-glossary-ux/mobile/cyf-glossary-term-edit.jpg";
import cyfMobileImg07 from "../data/ux/cyf-glossary-ux/mobile/cyf-glossary-term-flag.jpg";
import cyfMobileImg08 from "../data/ux/cyf-glossary-ux/mobile/cyf-glossary-profile-edit.jpg";
import cyfMobileImg09 from "../data/ux/cyf-glossary-ux/mobile/cyf-glossary-mentor-profile-edit.jpg";

const uxdesigns = [
	{
		id: "u1",
		type: "uxdesigns",
		name: "Portfolio",
		img: [portfolioImg00, portfolioImg01, portfolioImg02, portfolioImg03],
		desc: "Portfolio was design to be simple, based on a letterbox frame",
		role: "UX Designer",
		tech: "Balsamiq",
		challenge: "Development",
		solution: "Grind",
		source: {
			url: "",
			github: "",
			trello: "",
		},
	},
	{
		id: "u2",
		type: "uxdesigns",
		name: "Glossary: Desktop",
		img: [
			// cyfImg00,
			// cyfImg01,
			// cyfImg02,
			// cyfImg03,
			// cyfImg04,
			// cyfImg05,
			// cyfImg06,
			// cyfImg07,
			// cyfImg08,
			// cyfImg09,
			// cyfImg10,
			// cyfImg11,
			// cyfImg12,
			// cyfImg13,
			// cyfImg14,
		],
		desc:
			"CYF Glossary was inspired by platforms like Google and Wikipedia to display search result in a clear and concise manner.",
		role: "UX Designer",
		tech: "Balsamiq",
		challenge: "Development",
		solution: "Grind",
		source: {
			url: "",
			github: "",
			trello: "",
		},
	},
	{
		id: "u3",
		type: "uxdesigns",
		name: "Glossary: Mobile",
		img: [
			cyfMobileImg00,
			cyfMobileImg01,
			cyfMobileImg02,
			cyfMobileImg03,
			cyfMobileImg04,
			cyfMobileImg05,
			cyfMobileImg06,
			cyfMobileImg07,
			cyfMobileImg08,
			cyfMobileImg09,
		],
		desc:
			"CYF Glossary was inspired by platforms like Google and Wikipedia to display search result in a clear and concise manner.",
		role: "UX Designer",
		tech: "Balsamiq",
		challenge: "Development",
		solution: "Grind",
		source: {
			url: "",
			github: "",
			trello: "",
		},
	},
	{
		id: "u4",
		type: "uxdesigns",
		name: "Placeholder",
		img: ["#"],
		desc: "Placeholder",
		role: "Placeholder",
		tech: "Placeholder",
		challenge: "",
		solution: "",
		source: {
			url: "",
			github: "",
			trello: "",
		},
	},
	{
		id: "u5",
		type: "uxdesigns",
		name: "Placeholder",
		img: ["#"],
		desc: "Placeholder",
		role: "Placeholder",
		tech: "Placeholder",
		challenge: "",
		solution: "",
		source: {
			url: "",
			github: "",
			trello: "",
		},
	},
];

export default uxdesigns;
